import { useGetTrialUserProfileQuery } from "../trialAdminAPISlice"
import { useParams, useNavigate } from 'react-router-dom'
import { stringToTitleCase } from "../../../utils/stringUtils/titleCase"
import { formatDateTimeField } from "../../../utils/dateUtils/formatDateTimeField"
import { linkedRowProps } from "../../globalComponents/DataGrid/gridStyleProps/gridStyleProps"
import { useTheme } from "@emotion/react"
import { Box, Typography, Skeleton } from "@mui/material"
import { DataGridPro } from "@mui/x-data-grid-pro"
import DefaultNoRowsOverlay from "../../globalComponents/DataGrid/subComponents/noRowsOverlays/DefaultNoRowsOverlay"
import BreadCrumbHeader from "../../globalComponents/Headers/BreadCrumbHeader"
import BackButton from "../../globalComponents/SubHeader/Backbutton"
import { ActionButton } from "../../globalComponents/Buttons/ActionButton/ActionButton"
import { useState } from "react"
import TrialFinalApprovalModal from "../components/Modal/TrialFinalApprovalModal"
import { useSubmitFinalApprovalMutation } from "../trialAdminAPISlice"
import { enqueueSnackbar } from "notistack"
import TrialClinicianInfoBox from "../components/TrialUserProfile/TrialClinicianInfoBox"
import TrialStatusBox from "../components/TrialUserProfile/TrialStatusBox"
import { InitialApprovalChip, SubmissionStatusChip } from "../utils/trialChips"
import TrialClinicianInfoBoxLoader from "../components/TrialUserProfile/TrialClinicianInfoBoxLoader"
import TrialStatusBoxLoader from "../components/TrialUserProfile/TrialStatusBoxLoader"
import { BreadCrumbHeaderLoader } from "../../globalComponents/Loaders/commonComponent/BreadCrumbHeaderLoader"
import TrialReviewHistoryLoader from "../components/TrialUserProfile/TrialReviewHistoryLoader"
import { show400Error } from "../../../app/errors/genericErrors"
import { useSelector } from "react-redux"
import { selectCurrentUser } from "../../auth/authSlice"
import TrialUserStepper from "../components/TrialUserProfile/TrialUserStepper"
import openDocInNewTab from "../../../utils/apiUtils/openDocInNewTab"

const columns = [
    {
        field: 'date_submitted',
        headerName: 'Submitted date',
        flex: 1,
        sortable: false,
        valueGetter: (value) => value ? formatDateTimeField(value) : '-'
    },
    {
        field: 'referral_link_type',
        headerName: 'Encounter type',
        flex: 1,
    },
    {
        field: 'patient_name',
        headerName: 'Test patient',
        flex: 2,
        sortable: false,
        valueGetter: (value, row) => stringToTitleCase(value)
    },
    {
        field: 'col3',
        headerName: 'Submission status',
        flex: 1,
        sortable: false,
        renderCell: ({ row }) => <SubmissionStatusChip status={row.date_submitted} />

    },
    {
        field: 'reviewer_name',
        headerName: 'Reviewer',
        flex: 1,
        sortable: false,
        valueGetter: (value) => value ? value : '-'
    },
    {
        field: 'score_percentage',
        headerName: 'Score',
        flex: 1,
        sortable: false,
        valueGetter: (value, row) => value.reviewer_submitted_date ? `${value.score_percentage}%` : '-'
    },
    {
        field: 'feedback_status',
        headerName: 'Initial review',
        flex: 1,
        sortable: false,
        renderCell: ({ row }) => {
            const status = row.score_percentage === 'Awaiting grade' || !row.score_percentage.reviewer_submitted_date ? '' : row.score_percentage
            return <SubmissionStatusChip status={status} />
        }

    }
]

const sxProps = {
    border: 'none',
    "& .MuiDataGrid-virtualScroller": { overflow: "hidden" },
    '& .MuiDataGrid-columnHeaderTitleContainerContent': { '& .MuiCheckbox-root': { visibility: 'hidden' } },
    '.MuiDataGrid-detailPanel': {
        backgroundColor: 'rgba(0, 0, 0, 0.02)'
    },
    ...linkedRowProps
}

function TrialUserProfile() {


    const theme = useTheme()
    const navigate = useNavigate()
    const [openFinalApprovalModal, setOpenFinalApprovalModal] = useState(false)

    const { groups } = useSelector(selectCurrentUser)
    const isTrialAdmin = groups.hasOwnProperty('Trial User | Admin')

    const handleCloseFinalApprovalModal = () => setOpenFinalApprovalModal(false)
    const [submitFinalApproval, { isLoading: isSubmitting }] = useSubmitFinalApprovalMutation()

    const handleRowClick = ({ row }) => navigate(`/admin/trial-users/review/${row.referral_link_type}/${row.id}`)

    const { encounterType, trialUserId } = useParams()

    const { data: trialUser, isLoading, isFetching } = useGetTrialUserProfileQuery(trialUserId)

    const { name, review_state, average_score, resume_file } = trialUser?.info || {}
    const { trial_encounters } = trialUser || []

    const denyHandler = () => {
        submitFinalApproval({ trialUserId, payload: { finalApproval: 'denied' } })
            .unwrap()
            .then((res) => {
                enqueueSnackbar('User denied!', { variant: 'success' })
            }
            )
            .catch(err => show400Error(err))
    }

    const downloadResumeHandler = () => openDocInNewTab(`trial-users/resume/${trialUserId}`)

    if (isLoading) {
        return (
            <>
                <BreadCrumbHeaderLoader />
                <Box className='flex-col' p={3} gap={2}>
                    <Skeleton animation='wave' width={1200} height={60} sx={{ margin: '0 auto' }} />
                    <Skeleton animation='wave' width={130} height={30} />
                    <Box className='flex-row' gap={5} height={250}>
                        <TrialClinicianInfoBoxLoader />
                        <TrialStatusBoxLoader />
                    </Box>
                    <Skeleton animation='wave' width={130} height={30} />
                    <TrialReviewHistoryLoader />
                </Box>
            </>
        )
    }


    return (
        <>
            <BreadCrumbHeader
                startCrumb={'Admin'}
                title={name}
                crumbs={['Trial user', name]}
                startComponent={<BackButton />}
                endComponent={<ActionButton size='small' variant='outlined' onClick={downloadResumeHandler} sx={{ height: 32 }} disabled={!resume_file}> Download resume</ActionButton>}
            />

            <Box className='flex-col' p={3} gap={2}>
                <TrialUserStepper review_state={review_state} />
                <Typography sx={{ ...theme.typography.h6, fontWeight: 500 }}>Trial clinician information</Typography>
                <Box className='flex-row' gap={5}>
                    <TrialClinicianInfoBox theme={theme} trialUserInfo={trialUser?.info || {}} />
                    <TrialStatusBox
                        reviewState={review_state}
                        setOpenFinalApprovalModal={setOpenFinalApprovalModal}
                        denyHandler={denyHandler}
                        averageScore={average_score}
                        loading={isSubmitting}
                    />
                </Box>
                <Typography sx={{ ...theme.typography.h6, fontWeight: 500 }}>Trial review history</Typography>
                <Box sx={{ ...theme.standardBox }}>
                    <DataGridPro
                        columns={columns}
                        columnVisibilityModel={!isTrialAdmin ? { reviewer_name: false } : {}}
                        rows={trial_encounters || []}
                        pagination
                        autoHeight
                        disableColumnMenu
                        disableColumnResize
                        hideFooter
                        loading={isLoading || isFetching}
                        onRowClick={handleRowClick}
                        slots={{ noRowsOverlay: DefaultNoRowsOverlay, }}
                        slotProps={{ noRowsOverlay: { message: 'No assigned trial cases' } }}
                        sx={sxProps}
                    />
                </Box>
            </Box>
            <TrialFinalApprovalModal
                trialUserId={trialUserId}
                trialUserInfo={trialUser?.info || {}}
                open={openFinalApprovalModal}
                handleClose={handleCloseFinalApprovalModal} />
        </>
    )
}

export default TrialUserProfile
