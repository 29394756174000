import { Box, Typography, Divider } from '@mui/material'
import { useTheme } from '@emotion/react'
import { HaloTextField } from '../../../globalComponents/ReactHookFormFields'
import { ActionButton } from '../../../globalComponents/Buttons/ActionButton/ActionButton'
import { useForm } from "react-hook-form"
import { useSubmitFeedbackSectionMutation } from '../../trialAdminAPISlice'
import { show400Error } from '../../../../app/errors/genericErrors'
import { useNavigate } from 'react-router-dom'
import AnswerKeyChoice from './AnswerKeyChoice'
import { enqueueSnackbar } from 'notistack'
import TrialGradingLoader from './TrialGradingLoader'
import { useGradeFeedbackSectionMutation } from '../../trialAdminAPISlice'
import { useEffect } from 'react'

function TrialGrading({ feedback, trialEncounterId, setScore, loading, encounterType }) {
    const theme = useTheme()
    const navigate = useNavigate()
    const [submitFeedback, { isLoading: isSubmitting }] = useSubmitFeedbackSectionMutation()
    const [saveGeneralComment] = useGradeFeedbackSectionMutation()

    const { feedback_obj, feedback_sections, is_assigned_reviewer } = feedback || {}

    const feedbackSubmitted = Boolean(feedback_obj?.date_submitted)

    const { control, getValues, formState: { isDirty }, reset, setValue } = useForm({
        defaultValues: {
            generalComments: feedback_obj?.general_comments
        }
    })

    useEffect(() => {
        setValue('generalComments', feedback_obj?.general_comments)
    }, [feedback_obj])

    const submitFeedbackHandler = (e) => {
        e.preventDefault()

        let { generalComments } = getValues()

        const payload = {
            generalComments: generalComments
        }

        submitFeedback({ encounterType, trialEncounterId, payload })
            .unwrap()
            .then(() => {
                enqueueSnackbar('Feedback submitted!', { variant: 'success' })
                navigate(`/admin/trial-users/profile/${feedback_obj.trial_user}`)
            }
            )
            .catch(err => show400Error(err))
    }

    if (loading) {
        return (
            <>
                <TrialGradingLoader />
            </>
        )
    }

    const saveOnBlur = () => {
        if (isDirty) {
            const { generalComments } = getValues()
            saveGeneralComment({
                encounterType,
                trialEncounterId,
                payload: { generalComments, action: 'save comment' }
            })
                .unwrap()
                .then(res => {

                    // pass the values to update values to "compare"
                    reset({ generalComments }, { keepDirtyValues: true, keepDirty: false, })
                })
                .catch(err => show400Error(err))
        }
    }

    return (
        <Box sx={{ ...theme.standardBox, m: 3 }} component={'form'} noValidate onSubmit={submitFeedbackHandler}>
            <Box p={'11px 16px'}>
                <Typography variant='subtitle2'>{!feedbackSubmitted ? 'Answer key' : 'Score details'}</Typography>
            </Box>
            <Divider />
            {feedbackSubmitted ?
                <>
                    <Box p={2}>
                        <Typography sx={{ ...theme.typography.subtitle2 }}>General comments</Typography>
                        <Typography sx={{ ...theme.typography.body2 }}>{feedback_obj?.general_comments}</Typography>
                    </Box>
                    <Divider />
                </>
                : null}

            <Box className='flex-col' pt={1}>
                {feedback_sections?.map((section) =>
                    <AnswerKeyChoice
                        key={section.id}
                        theme={theme}
                        sx={(theme) => ({ color: theme.palette.text.disabled })}
                        isSubmitting={isSubmitting}
                        control={control}
                        section={section}
                        trialEncounterId={trialEncounterId}
                        setScore={setScore}
                        isAssignedReviewer={is_assigned_reviewer}
                        feedbackSubmitted={feedbackSubmitted}
                    />)}
                {!feedbackSubmitted ?
                    <>
                        <Divider />
                        <Box p={'16px 16px 8px 16px'}>
                            <Typography variant='subtitle2'>General comments</Typography>
                            <HaloTextField
                                name='generalComments'
                                control={control}
                                onBlur={saveOnBlur}
                                multiline
                                minRows={5}
                                maxRows={20}
                                maxLength={5000}
                                sx={{ p: '8px 0px' }} />
                        </Box>
                    </> : null}
            </Box>
            {is_assigned_reviewer && !feedbackSubmitted ?
                <Box className='flex-row-flex-end' p={'0px 16px 32px 16px'}>
                    <ActionButton
                        type='submit'
                        variant='contained'
                        color='success'
                        loading={isSubmitting}>
                        Submit
                    </ActionButton>
                </Box> : null}

        </Box>

    )
}

export default TrialGrading
